import SalesService from "../../services/sales.service";
import {useContext} from "react";
import Utils from "../../utils/Utils";
import ProductFactory from "../../models/ProductFactory";
import Toasts from "../notificacion/Toasts";
import {StateContext} from "../context/StateProvider";
import Order, {OrderType} from "../../models/Order";
import StickerService from "../../services/sticker.service";
import DeliveryService from "../../services/delivery.service";
import OrderService from "../../services/order.service";


const useSalesOrder = () => {

    const {state, updateState} = useContext(StateContext);

    /**
     * Obtener bultos punteados
     * @param idDelivery
     * @param order
     * @param year
     * @returns {Promise<void>}
     */
    const getDeliveryPackages = async (idDelivery, order, year) => {
        let packages = await DeliveryService.getBultos(idDelivery, order.num, year);
        let registerDate = null;
        packages.map((pack) => {
            if (pack.registrado !== null && (registerDate === null || new Date(pack.registrado) > registerDate)) {
                registerDate = new Date(pack.registrado);
            }
        })
        order.packages = packages;
        order.lastRegisterPackage = registerDate;
    }

    /**
     * Comprobar si tiene productos en nave 2
     * @param order
     * @returns {Promise<void>}
     */
    const checkOrderN2 = async (order) => {
        let update = false;

        if (order === null) {
            order = state.order;
            update = true;
        }

        if (order.num !== null) {

            const response = await OrderService.checkOrderN2(order.num, state.year);

            if (response?.details?.existen) {

                const orderState = await OrderService.getStateOrderN2(order.num, state.year);

                if (orderState.length > 0 && (orderState[0].estado !== order.state || orderState[0].preparado !== order.preparerN2)) {
                    order.stateN2 = (orderState[0].estado === null ? '1' : orderState[0].estado);
                    order.preparerN2 = (orderState[0].preparado === null ? '0' : orderState[0].preparado);
                } else {
                    update = false;
                }

                order.n2 = true;

                if (update) {
                    updateState({order: order});
                }
            }
        }
    }

    /**
     * Obtener personas asignadas al pedido
     * @param order
     * @returns {Promise<void>}
     */
    const getPersonsOrder = async (order, year) => {
        const persons = await OrderService.getPersonsOrder(order.num, 'ventas', year);
        if (persons.length > 0) {
            order.reviewer = (persons[0].revisado === null ? '0' : persons[0].revisado);
            order.preparer = (persons[0].preparado === null ? '0' : persons[0].preparado);
            order.camera = persons[0].camara;
            order.state = persons[0].estado;
        }
    }

    /**
     * Obtener información ruta
     * @param order
     * @param year
     * @returns {Promise<void>}
     */
    const getDeliveryOrder = async (order, year) => {
        let infoOrder = Utils.getOrder(order.num);
        let sticker = await StickerService.getStickerMap(infoOrder.serie, infoOrder.order, year);
        if (sticker) {
            let delivery = await DeliveryService.getDelivery(sticker.delivery);
            order.delivery = delivery;
            order.sticker = sticker;
        }
    }

    /**
     * Generar pedido
     * @param lastUpdated
     * @param complete
     * @returns {Promise<void>}
     */
    const createOrder = async (orderNum, lastUpdated, complete) => {

        let order = new Order();
        order.num = orderNum;
        order.complete = complete;

        if (lastUpdated) {
            order.setLastUpdated(lastUpdated);
        }

        // Obtener informacion ruta
        await getDeliveryOrder(order, state.year);

        // Obtener personas asignadas al pedido
        await getPersonsOrder(order, state.year);

        // Comprobar si existe productos en nave 2
        await checkOrderN2(order);

        // Obtener bultos punteados
        if (order.delivery?.id) {
            await getDeliveryPackages(order.delivery.id, order, state.year);
        }

        updateState({order: order});
    }

    /**
     * Obtener ventas en funcion de la orden
     * @param updateState
     * @param state
     * @returns {Promise<void>}
     */
    const getSales = async (orderNum, nave) => {

        try {
            const data = await SalesService.getSales(orderNum, OrderType.Albaran.valueOf(), state.year);

            if (data.length > 0) {

                let complete = 0;
                let lastUpdated = null;

                const salesMap = data.map(async (sale, index) => {

                    let stateProduct = 0;
                    let remaining = sale.UNIDADES;
                    let register = 0;
                    let registerDate = undefined;
                    let manual = false;

                    if (!Utils.checkRefProduct(sale.ARTICULO)) {
                        let refArticulo = sale.ARTICULO;

                        if (sale.DUPLICADO) {
                            refArticulo += '-' + sale.LINIA;
                        }

                        const saleRegistry = await SalesService.getSalesProduct(sale.LETRA + '-' + sale.NUMERO, refArticulo, state.year, nave);

                        if (saleRegistry.length > 0) {
                            remaining = sale.UNIDADES - saleRegistry[0].unidades;
                            register = saleRegistry[0].unidades;

                            if (remaining === 0) {
                                stateProduct = 1;
                                complete += 1;
                            }

                            registerDate = saleRegistry[0].registrado;
                            manual = saleRegistry[0].manual;

                            if (lastUpdated === null || lastUpdated < new Date(saleRegistry[0].registrado)) {
                                lastUpdated = new Date(saleRegistry[0].registrado);
                            }
                        }
                    } else {
                        sale.UNIDADES = 0;
                        remaining = 0;
                        stateProduct = 1;
                        complete += 1;
                    }

                    let barrasModels = 'BarrasModels';
                    if (state.year !== Utils.getCurrentYear()) {
                        barrasModels = 'BarrasModel' + state.year + 's';
                    }

                    return ProductFactory.productOrder(sale.LETRA + '-' + sale.NUMERO, sale.ARTICULO, sale.INFO_ARTICULO[barrasModels], sale.INFO_ARTICULO.NOMBRE,
                        sale.UNIDADES, sale.INFO_ARTICULO.UBICACION, sale.INFO_ARTICULO.VENSERIE, remaining, stateProduct, register, sale.DUPLICADO, sale.LINIA, registerDate, manual, index);
                });

                const salesMapFull = await Promise.all(salesMap);
                await createOrder(orderNum, lastUpdated, true);
                updateState({ products: salesMapFull });
            }

            updateState({ loading: false });
        } catch (error) {
            const toasts = Toasts.generateToast(error.response.data.message, 'error');
            updateState({ toasts: [...state.toasts, toasts] });
        }
    };

    return {getSales, checkOrderN2}
}

export default useSalesOrder;