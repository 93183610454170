import React, {useState, useContext, useEffect} from 'react';
import {CCard, CCardBody, CCol, CFormCheck, CFormInput} from "@coreui/react";
import {StateContext} from "../components/context/StateProvider";
import ConfigService from "../services/config.service";
import {CONFIG_MARQUESINA, CONFIG_MARQUESINA_DESP} from "../config/constants";
import Button from "react-bootstrap/Button";
import Toasts from "../components/notificacion/Toasts";


const Config = () => {

    const {state, updateState} = useContext(StateContext);
    const [marquee, setMarquee] = useState({name: 'marquesina', value: ''});
    const [marqueeDesp, setMarqueeDesp] = useState({name: 'marquesina_desp', value: ''});

    useEffect(() => {
        const fetchMarque = () => {
            ConfigService.getConfig(CONFIG_MARQUESINA).then((data) => {
                setMarquee({name: marquee.name, value: (data.value === null ? '' : data.value)});
            });
            ConfigService.getConfig(CONFIG_MARQUESINA_DESP).then((data) => {
                setMarqueeDesp({name: marqueeDesp.name, value: data.value});
            });
        }

        fetchMarque();
    }, []);

    const handleChange = (event, type) => {
        if (type === marquee.name) {
            setMarquee({name: marquee.name, value: event.target.value})
        } else {
            setMarqueeDesp({name: marqueeDesp.name, value: event.target.checked.toString()})
        }
    }

    const handleSave = async () => {
        try {
            await ConfigService.createConfig(marquee.name, marquee.value);
            await ConfigService.createConfig(marqueeDesp.name, marqueeDesp.value);
            updateState({toasts: [...state.toasts, Toasts.generateToast('Se ha guardado la configuración', 'success')]})
        } catch (error) {
            updateState({toasts: [...state.toasts, Toasts.generateToast(error.message, 'error')]})
        }
    }

    return (
        <CCol id="state-orders-tv" md={12}>
            <CCard className="pt-4 ps-4 pe-4 col-12 mb-3 ms-2 me-2">
                <CCardBody>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Texto marquesina:</label>
                        <CFormInput className="form-control" type="text" id="marquee" name="marquee"
                                    value={marquee.value} onChange={(e) => handleChange(e, marquee.name)}
                                    placeholder='Texto marquesina'/>
                    </div>
                    <div>
                        <CFormCheck id="marquee_desp" label="Fijar texto marquesina" defaultChecked={marqueeDesp.value === 'true'} onChange={(e) => handleChange(e, marqueeDesp.name)}/>
                    </div>
                </CCardBody>
                <CCardBody className="pt-0">
                    <Button variant="primary" onClick={() => handleSave()}>
                        Guardar
                    </Button>
                </CCardBody>
            </CCard>
        </CCol>
    );
}

export default Config;