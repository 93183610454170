import React from "react";
import {StateContext} from "../components/context/StateProvider";
import withRouter from "../components/withRouter";
import {DataGrid, esES, GridActionsCellItem, GridRowModes,} from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {Button} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import HouseIcon from '@mui/icons-material/House';
import PrintIcon from '@mui/icons-material/Print';
import CommentIcon from '@mui/icons-material/Comment';
import {CButton, CCard, CCardBody, CCol, CForm, CFormInput, CSpinner} from "@coreui/react";
import Address from "../components/modals/Address";
import OrderService from "../services/order.service";
import Toasts from "../components/notificacion/Toasts";
import StickerService from "../services/sticker.service";
import Weight from "../components/modals/Weight";
import ReactToPrint from "react-to-print";
import PrintSticker from "../components/sticker/PrintSticker";
import DeliveryService from "../services/delivery.service";
import Sticker from "../models/Sticker";
import ConfirmationM from "../components/modals/ConfirmationM";
import DataGridCrudBase from "../components/datagrid/DataGridCrudBase";
import Box from "../models/Box";
import Utils from "../utils/Utils";
import PrintDelivery from "../components/sticker/PrintDelivery";
import * as XLSX from 'sheetjs-style';
import ExcelUtils from "../utils/ExcelUtils";
import PrintStickerAsigna from "../components/sticker/PrintStickerAsigna";
import DeliveryNavigate from "../components/sticker/DeliveryNavigate";
import successSound from "../assets/audio/success.mp3";
import errorSound from "../assets/audio/error.mp3";
import warningSound from "../assets/audio/warning.mp3";
import SalesService from "../services/sales.service";
import Comment from "../components/modals/Comment";

class Stickers extends DataGridCrudBase {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            order: '',
            delivery: {},
            stickers: [],
            transport: '',
            print: {},
            stickersModels: {},
            showAddress: false,
            showComment: false,
            showWeight: false,
            showConfirmation: false,
            showDeleteConfirmation: false,
            loading: true,
            sticker: undefined,
            text: '',
            title: '',
            disableConfirm: false,
            modelGrid: 'stickersModels',
            stateGrid: 'stickers',
            view: false,
            handleConfirmModal: null,
            handleCancelModal: null,
            employeeDefault: null
        };
        this.apiRef = React.createRef();
        this.apiRef.current = {};
        this.stickerAsignaRef = React.createRef();
        this.orderRef = React.createRef();
    }

    /**
     * Monta el componente
     * @returns {Promise<void>}
     */
    async componentDidMount() {

        const {state, updateState} = this.context;

        try {
            this.state.view = !Utils.isEmpty(this.props.search.get('view')) ? this.props.search.get('view') : false;
            if (this.state.view) {
                this.state.print = [];
            }

            // Obtener datos ruta
            await DeliveryService.getDelivery(this.props.search.get('id')).then(
                (data) => {
                    this.setState({delivery: data});
                }
            )

            // Obtener transportista
            await DeliveryService.getTransports().then(
                (data) => {
                    this.setState({transport: data.find((transport) => transport.CODIGO === this.state.delivery.transport)})
                }
            )

            // Obtener rutas
            this.getStickers();

        } catch (error) {
            updateState({toasts: [...state.toasts, Toasts.generateToast(error.message, 'error')]})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.search.get('id') !== this.props.search.get('id')) {
            this.setState({loading: true});
            this.componentDidMount();
        }
    }

    /**
     * Obtener etiquetas
     * @returns {Promise<void>}
     */
    getStickers = async () => {
        await StickerService.getStickers(this.props.search.get('id')).then(
            async (data) => {

                let packages = 0;
                let weight = 0;

                const stickersMap = data.map(async (sticker) => {
                    const stickerObject = new Sticker(sticker.serie + '-' + sticker.albaran,
                        sticker.serie, sticker.albaran, sticker.cliente, sticker.bultos, sticker.id_agencia, sticker.agencia,
                        sticker.nombre, sticker.direccion, sticker.cp, sticker.poblacion, sticker.provincia, sticker.id_ruta, sticker.ruta, sticker.telefono,
                        sticker.id_empleado, sticker.empleado, sticker.comprobado, sticker.creado, sticker.id_cliente, sticker.forma_pago, sticker.expedicion_asigna,
                        this.state.delivery.date, this.state.delivery.counter, sticker.dropshipping, sticker.imprimir
                    );
                    stickerObject.comment = sticker.comentario;

                    if (this.state.view) {
                        const packagesState = await DeliveryService.getBultos(this.props.search.get('id'), sticker.serie + '-' + sticker.albaran, this.state.delivery.year);
                        if (packagesState.length === stickerObject.packages) {
                            stickerObject.scanned = true;
                        }
                    }

                    if (sticker.peso !== null) {
                        stickerObject.boxes = JSON.parse(sticker.pesos);
                        stickerObject.weight = sticker.peso;
                    }

                    const customer = await OrderService.getCustomerOrder(sticker.serie, sticker.albaran, this.state.delivery.year);
                    stickerObject.listCustomerAdress = customer[0].ClientesModel.EnviCliModels;
                    stickerObject.tradename = customer[0].ClientesModel.NOMBRE2;
                    stickerObject.phonesDefault = customer[0].ClientesModel.TelfCliModels;
                    return stickerObject;
                });

                const stickersMapFull = await Promise.all(stickersMap);
                this.setState({stickers: stickersMapFull, loading: false, packages: packages, weight: weight});
            }
        );
    }

    handleSave = async (id) => {
        const {state, updateState} = this.context;
        let row = this.apiRef.current.getRowWithUpdatedValues(id);

        if (Utils.isEmpty(row.customer) || Utils.isEmpty(row.order)) {
            let message = 'No se puede añadir etiqueta sin cliente asociado';
            if (Utils.isEmpty(row.order)) {
                message = 'No se puede añadir etiqueta sin albarán asociado';
            }
            updateState({toasts: [...state.toasts, Toasts.generateToast(message, 'error')]});

        } else if (row.nameEmployee === '' || Utils.isEmpty(row.nameEmployee) || Utils.isEmpty(row.employee) || row.employee === '') {
            updateState({toasts: [...state.toasts, Toasts.generateToast('No se puede añadir etiqueta sin empleado', 'error')]})
        } else {
            const validateSticker = await this.validateSticker(row, row.order, updateState, state, row.id, true);
            if (validateSticker) {
                this.apiRef.current.updateRows([
                    {
                        id: row.id,
                        ...row,
                    },
                ]);
                this.handleGridSave(id, this.state.modelGrid);
            }
        }
    };

    handleComment(id) {
        this.setState({showComment: true, sticker: this.state.stickers.find((row) => row.id === id)});
    }

    handleAddress(id) {
        this.setState({showAddress: true, sticker: this.state.stickers.find((row) => row.id === id)});
    }

    async checkPrint(printSticker) {
        if (printSticker.print) {
            this.setState({
                showConfirmation: true,
                text: '¿Quiere volver a imprimir la etiqueta <strong>' + printSticker.serie + '-' + printSticker.order + '?<\strong>',
                title: 'Atención',
                disableConfirm: false,
                handleConfirmModal: this.printSticker.bind(this, printSticker, true),
                handleCancelModal: this.handleClose.bind(this, 'showConfirmation', false)
            });
        } else {
            await this.printSticker(printSticker, false);
        }
    }

    async printSticker(printSticker, modal) {
        if (this.state.delivery.asigna) {
            await this.stickerAsignaRef.current.printSticker(printSticker);
        }
        this.setState({print: printSticker}, () => {
            this.componentPrintRef.handleClick()
        });

        if (modal) {
            this.handleClose('showConfirmation', false);
        }
    }

    async handlePrint(id) {
        if (id !== undefined) {
            const printSticker = this.state.stickers.find((row) => row.id === id);
            const {state, updateState} = this.context;

            try {
                // Comprobar series
                await SalesService.checkSeriesProductsOrder(printSticker.getOrder(), state.year);

                // Comprobar punteos de todos los pedidos
                await SalesService.checkProductsRegisteredOrder(printSticker.getOrder(), state.year);

                this.checkPrint(printSticker);
            } catch (error) {

                if (error.response.data.code === 422) {
                    this.setState({
                        showConfirmation: true,
                        text: 'No se han punteado todos los productos del albarán <strong>' + printSticker.serie + '-' + printSticker.order + '<\strong>',
                        title: 'Atención',
                        disableConfirm: false,
                        handleConfirmModal: this.checkPrint.bind(this, printSticker),
                        handleCancelModal: this.handleClose.bind(this, 'showConfirmation', false)
                    });
                } else {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    let errors = [];
                    const toasts = Toasts.generateToast(resMessage, 'error');
                    errors.push(toasts);
                    if (error.response.data.details) {
                        for (let i = 0; i < error.response.data.details.length; i++) {
                            const toasts = Toasts.generateToast(error.response.data.details[i], 'warning');
                            errors.push(toasts);
                        }
                    }
                    updateState({toasts: [...state.toasts, ...errors]});
                }
            }

        } else {
            this.setState({print: this.state.stickers}, () => this.componentPrintRef.handleClick());
        }
    }

    handleExport() {
        const fileExtension = '.xlsx';

        let fileName = Utils.formatDateWithoutHour(Date.parse(this.state.delivery.date));

        if (this.state.delivery.asigna) {
            fileName = 'Cobros asigna';
        }

        const deliveryHeaders = ['RUTA', 'NOMBRE', 'FECHA', 'BULTOS TOTALES', 'EXPEDICIONES', 'M3 TOTALES'];
        const stickersHeaders = ['DOCUMENTO', 'ID CLIENTE', 'NOMBRE CLIENTE', 'FORMA PAGO', 'BULTOS', 'AGENCIA', 'M3'];

        const delivery = [{
            'RUTA': this.state.delivery.id,
            'NOMBRE': this.state.delivery.name,
            'FECHA': Utils.formatDateWithoutHour(Date.parse(this.state.delivery.date)),
            'BULTOS TOTALES': this.state.delivery.packages,
            'EXPEDICIONES': this.state.delivery.expedition,
            'M3 TOTALES': this.state.delivery.weight
        }];

        const wsDelivery = XLSX.utils.json_to_sheet(delivery, {header: deliveryHeaders});

        ExcelUtils.addStyleHeaderCell(deliveryHeaders, wsDelivery, 0);

        ExcelUtils.addSheetAppend(wsDelivery, []);
        ExcelUtils.addSheetAppend(wsDelivery, []);
        ExcelUtils.addSheetAppend(wsDelivery, stickersHeaders);

        this.state.stickers.forEach((sticker) => {
            const stickerObject = [sticker.id, sticker.id_customer, sticker.customer, sticker.way_pay, sticker.packages, sticker.nameAgency];
            if (this.state.delivery.barito) {
                stickerObject.push(sticker.weight);
            }

            ExcelUtils.addSheetAppend(wsDelivery, stickerObject);
        })

        ExcelUtils.addStyleHeaderCell(stickersHeaders, wsDelivery, 4);

        ExcelUtils.adjustColumnsWidth(wsDelivery);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, wsDelivery, 'reparto');
        XLSX.writeFile(wb, fileName + fileExtension, {bookType: 'xlsx', type: 'blob'});
    }

    handleWeight(id) {
        this.setState({showWeight: true, sticker: this.state.stickers.find((row) => row.id === id)});
    }

    handleRowEditStop = (params, event) => {
        this.handleGridRowEditStop(params, event);
    };

    handleRowEditStart = (params, event) => {
        this.clearGridRowEdit(this.state.stateGrid, this.state.modelGrid);
    }

    handleRowModesModelChange = (newRowModesModel) => {
        this.handleGridRowModesModelChange(newRowModesModel, this.state.modelGrid);
    };

    handlePackagesWeight(delivery, stickers, updateDelivery) {

        const {state, updateState} = this.context;

        try {
            delivery.packages = stickers.reduce((total, obj) => total + obj['packages'], 0);
            delivery.weight = Box.calculateM3(stickers.reduce((total, obj) => total + obj['weight'], 0));
            delivery.expedition = new Set(stickers.map(obj => obj.name)).size;

            if (updateDelivery) {
                this.setState({delivery: delivery});
            }
            DeliveryService.updateDelivery(delivery, false);

        } catch (error) {
            const toasts = Toasts.generateToast(error.message, 'error');
            updateState({toasts: [...state.toasts, toasts]})
        }

    }

    /**
     * Modal confirmacion borrado
     * @param id
     * @returns {Promise<void>}
     */
    async handleConfirmDelete(id) {

        const sticker = this.state.stickers.find((row) => row.id === id);

        this.setState({
            showConfirmation: true,
            sticker: sticker,
            text: '¿Esta seguro que quiere eliminar la etiqueta: <strong>' + sticker.serie + '-' + sticker.order + '<\strong>',
            title: 'Atención',
            disableConfirm: false,
            handleConfirmModal: this.handleDelete.bind(this, id),
            handleCancelModal: this.handleClose.bind(this, 'showConfirmation', false)
        });
    }

    /**
     * Gestionar borrado de etiqueta
     * @param id
     * @returns {Promise<void>}
     */
    async handleDelete(id) {
        const {state, updateState} = this.context;
        const sticker = this.state.stickers.find((row) => row.id === id);

        StickerService.deleteSticker(sticker.serie, sticker.order, sticker.counterDelivery, state.year).then(
            () => {
                const toasts = Toasts.generateToast('Se ha eliminado etiqueta correctamente', 'success');
                updateState({toasts: [...state.toasts, toasts]})
                this.handleGridDelete(id, this.state.stateGrid, this.handlePackagesWeight.bind(this, this.state.delivery, this.state.stickers, true));
            }
        ).catch((error) => {
            const toasts = Toasts.generateToast(error.message, 'error');
            updateState({toasts: [...state.toasts, toasts]})
        });

        this.handleClose('showConfirmation', false);
    }

    /**
     * Gestiona cuando se actualiza fila de la tabla
     * @param newRow
     * @param rowOld
     * @returns {Promise<{boxes: [], delivery: *, address: *, agency: *, created: *, nameAgency: *, county: *, weight: number, isNew: boolean, packages: *, cp: *, nameDelivery: *, listCustomerAdress: [], phone: *, serie: *, name: *, id: *, state: *, order: *, customer: *}>}
     */
    processRowUpdate = async (newRow, rowOld) => {

        if ((newRow.serie + '-' + newRow.order !== rowOld.id && typeof rowOld.id !== 'number') || newRow.overrideRoute) {
            const {state} = this.context;
            let serie = newRow.serie;
            let order = newRow.order;

            if (!newRow.isNew) {
                const id = rowOld.id.split('-');
                serie = id[0];
                order = id[1];
            }

            StickerService.deleteSticker(serie, order, (newRow.overrideRoute ? this.state.sticker.counterDelivery : newRow.counterDelivery), state.year);
            if (newRow.overrideRoute) {
                StickerService.getStickers(this.state.sticker.delivery.id).then(
                    (data) => {

                        const stickersMap = data.map((sticker) => {
                            return new Sticker(sticker.serie + '-' + sticker.albaran,
                                sticker.serie, sticker.albaran, sticker.cliente, sticker.bultos, sticker.id_agencia, sticker.agencia,
                                sticker.nombre, sticker.direccion, sticker.cp, sticker.poblacion, sticker.provincia, sticker.id_ruta, sticker.ruta, sticker.telefono,
                                sticker.id_empleado, sticker.empleado, sticker.comprobado, sticker.creado, sticker.id_cliente, sticker.forma_pago, sticker.expedicion_asigna,
                                this.state.sticker.delivery.date, this.state.sticker.counterDelivery, sticker.dropshipping, sticker.imprimir
                            );
                        });

                        this.handlePackagesWeight(this.state.sticker.delivery, stickersMap, false)
                    }
                );
            }
        }

        const sticker = new Sticker(newRow.serie + '-' + newRow.order, newRow.serie, newRow.order, newRow.customer, (newRow.packages ? newRow.packages : 0), newRow.agency, newRow.nameAgency,
            newRow.name, newRow.address, newRow.cp, newRow.county, newRow.state, newRow.delivery, newRow.nameDelivery, newRow.phone, newRow.employee, newRow.nameEmployee, newRow.check,
            newRow.created, newRow.id_customer, newRow.way_pay, newRow.expedicionAsigna, this.state.delivery.date, this.state.delivery.counter, newRow.dropshipping, newRow.imprimir);
        sticker.listCustomerAdress = newRow.listCustomerAdress;
        sticker.weight = newRow.weight ? newRow.weight : 0;
        sticker.boxes = newRow.boxes;
        sticker.isNew = false;

        return await this.processGridRowUpdate(newRow, rowOld, sticker, this.state.stateGrid, this.state.modelGrid, async (stateGrid) => {
            const {state, updateState} = this.context;
            if (sticker.order !== undefined && sticker.serie !== undefined && sticker.nameEmployee !== '') {

                try {
                    await StickerService.registerSticker(sticker).then(
                        async () => {
                            let toastMessage = 'Se ha modificado etiqueta correctamente';
                            if (newRow.isNew) {
                                toastMessage = 'Se ha creado la etiqueta correctamente';
                            }

                            state.toasts.push(Toasts.generateToast(toastMessage, 'success'));
                            updateState({toasts: state.toasts});
                            if (this.state.order !== '') {
                                setTimeout(() => {
                                    this.orderRef.current.focus();
                                }, 500);
                                this.setState({
                                   order: ''
                                });
                            }

                            this.getStickers();
                        }
                    );

                    this.setState({
                        [stateGrid]: this.state[stateGrid].map((row) => (row.id === newRow.id ? sticker : row))
                    }, () => {
                        this.handlePackagesWeight(this.state.delivery, this.state.stickers, true);
                    });

                } catch (error) {
                    state.toasts.push(Toasts.generateToast(error.message, 'error'));
                    updateState({toasts: state.toasts})
                }

            } else {
                let message = 'Etiqueta no válida en albarán o serie';
                if (sticker.nameEmployee === '') {
                    message = 'Etiqueta sin empleado asignado';
                }
                updateState({toasts: [...state.toasts, Toasts.generateToast(message, 'error')]});
                return {};
            }
        });
    };

    onProcessRowUpdateError = (error) => {
        console.error(error);
    }

    /**
     * Genera boton para añadir a la lista
     * @returns {JSX.Element}
     */
    editToolbar = () => {
        if (!this.state.view) {
            return this.gridToolbar(this.state.stateGrid, this.state.modelGrid, 'Añadir etiqueta', 'order', () => {
                const id = Date.now();
                let sticker = new Sticker();
                sticker.id = id;
                sticker.delivery = this.props.search.get('id');
                sticker.nameDelivery = this.props.params.delivery;
                sticker.agency = this.state.delivery.transport;
                sticker.nameAgency = this.state.transport.NOMBRE;
                sticker.created = new Date();
                sticker.serie = '01';
                sticker.isNew = true;
                return sticker;
            });
        }

        return (<></>);
    }

    /**
     * Genera las columnas de la tabla
     * @param barito
     * @returns {[{hide: boolean, headerName: string, field: string, flex: number},{headerName: string, field: string, flex: number, editable: boolean},{headerName: string, field: string, flex: number, editable: boolean},{headerName: string, field: string, flex: number, editable: boolean},{headerName: string, field: string, flex: number, editable: boolean, type: string},null,null,null,null,null]}
     */
    generateColumns(barito, asigna) {

        let columns = [
            {
                field: 'id',
                headerName: 'Id',
                flex: 1,
                hide: true,
            },
            {
                field: 'check',
                headerName: 'Comprobado',
                type: 'boolean',
                flex: 0.5,
                editable: !this.state.view,
            },
            {
                field: 'serie',
                headerName: 'Serie',
                flex: 0.5,
                editable: !this.state.view,
            },
            {
                field: 'order',
                headerName: 'Albarán',
                flex: 0.8,
                type: 'number',
                editable: !this.state.view,
            },
            {
                field: 'id_customer',
                headerName: 'Id cliente',
                flex: 2,
                editable: false,
            },
            {
                field: 'customer',
                headerName: 'Cliente',
                flex: 2,
                editable: false,
            },
            {
                field: 'way_pay',
                headerName: 'Forma pago',
                flex: 2,
                editable: false,
            },
            {
                field: 'employee',
                headerName: 'Id empleado',
                flex: 0.5,
                editable: !this.state.view,
            },
            {
                field: 'nameEmployee',
                headerName: 'Empleado',
                flex: 1,
                editable: false,
            },
            {
                field: 'packages',
                headerName: 'Bultos',
                flex: 0.75,
                editable: !this.state.view,
                type: 'number'
            },
            {
                field: 'expedicionAsigna',
                headerName: 'Expedición',
                flex: 0.75,
                editable: !this.state.view,
                type: 'string'
            },
            {
                field: 'weight',
                headerName: 'Peso',
                flex: 1.2,
                editable: !this.state.view,
                type: 'number',
                renderCell: (params) => {
                    const handleButtonClick = () => {
                        this.handleWeight(params.id);
                    };

                    const handleChange = (event) => {
                    };

                    if (!this.state.view) {
                        return (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="number"
                                    value={params.value || 0}
                                    onChange={handleChange}
                                    style={{
                                        width: '80%',
                                        textAlign: 'right',
                                        border: '0',
                                        backgroundColor: 'transparent',
                                        outline: '0'
                                    }}
                                />
                                <Button style={{fontSize: '0.6rem'}} className='bg-primary text-white'
                                        onClick={handleButtonClick}>Calcular</Button>
                            </div>
                        );
                    }

                    return (<>{params.value}</>);
                },
            },
            {
                field: 'agency',
                headerName: 'Agencia',
                flex: 0.5,
                editable: false,
            },
            {
                field: 'nameAgency',
                headerName: 'Nombre agencia',
                flex: 2,
                editable: false,
            },
            {
                field: 'created',
                headerName: 'Creado',
                flex: 1,
                editable: false,
                hide: true,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                width: 210,
                cellClassName: 'actions',
                getActions: ({id}) => {
                    const isInEditMode =
                        this.state.stickersModels[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon/>}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={() => this.handleSave(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon/>}
                                label="Cancel"
                                className="textPrimary"
                                onClick={() => this.handleGridCancel(id, this.state.stateGrid, this.state.modelGrid)}
                                color="inherit"
                            />,
                        ];
                    }

                    return [
                        <GridActionsCellItem
                            icon={<CommentIcon />}
                            label="PrintSticker"
                            className="textPrimary"
                            onClick={() => this.handleComment(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<PrintIcon/>}
                            label="PrintSticker"
                            className="textPrimary"
                            onClick={() => this.handlePrint(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<HouseIcon/>}
                            label="Address"
                            className="textPrimary"
                            onClick={() => this.handleAddress(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => this.handleGridEdit(id, this.state.stateGrid, this.state.modelGrid)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label="Delete"
                            onClick={() => this.handleConfirmDelete(id)}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];

        if (this.state.view) {
            columns = columns.filter((row) => (row.field !== 'actions'))
        }

        if (!barito) {
            columns = columns.filter((row) => (row.field !== 'weight'))
        }

        if (!asigna) {
            columns = columns.filter((row) => (row.field !== 'expedicionAsigna'))
        }

        return columns;
    }

    /**
     * Gestiona cerra el modal
     * @param show
     */
     handleClose = async (show, reset) => {
        this.setState({[show]: false});

        if (show === 'showConfirmation' && reset) {
            let row = this.apiRef.current.getRow(this.state.sticker.id);
            await this.apiRef.current.setEditCellValue({id: row.id, field: 'order', value: ''});
        }
    }

    /**
     * Gestiona la asignación de la dirección de la etiqueta
     * @param sticker
     * @param type
     * @returns {Promise<void>}
     */
    async handleConfirm(sticker, type) {

        const {state, updateState} = this.context;
        let message = '';
        let modal = '';

        if (type === 'address') {
            if (sticker.name !== ''
                || sticker.address !== ''
                || sticker.cp !== ''
                || sticker.state !== ''
                || sticker.county !== '') {
                message = 'Se modificado direccion correctamente';
            }
            modal = 'showAddress';
        } else  if (type === 'comment') {
            message = 'Se modificado comentario correctamente';
            modal = 'showComment';
        } else {
            message = 'Se modificado peso correctamente';
            modal = 'showWeight';
        }

        StickerService.registerSticker(sticker).then(
            () => {
                let toasts = Toasts.generateToast(message, 'success');
                updateState({toasts: [...state.toasts, toasts]});

                this.setState({
                    stickers: this.state.stickers.map((row) => (row.id === sticker.id ? sticker : row)),
                    [modal]: false
                }, () => {
                    this.handlePackagesWeight(this.state.delivery, this.state.stickers, true);
                });
            }
        ).catch((error) => {
            const toasts = Toasts.generateToast(error.message, 'error');
            updateState({toasts: [...state.toasts, toasts]})
        });
    }

    /**
     * Gestionar modal cuando se detecta que la etiqueta ya existe en otra ruta
     * @returns {Promise<void>}
     */
    handleConfirmCustomerSticker = async () => {
        const {state, updateState} = this.context;
        let row = this.apiRef.current.getRow(this.state.sticker.id);
        await this.updateCustomerRow(row, this.state.sticker.order, updateState, state);
        row.overrideRoute = true;
        this.apiRef.current.updateRows([
            {
                id: row.id,
                ...row,
            },
        ]);

        const delivery = await DeliveryService.getDelivery(this.state.sticker.delivery);
        this.state.sticker.counterDelivery = delivery.counter;
        this.state.sticker.delivery = delivery;

        this.handleClose('showConfirmation');

        // Necesario timeout para esperar que se cierre el modal para pasar a la siguiente columna
        setTimeout(() => {
            this.nextColumn(this.state.sticker.id, 'order', this.state.stateGrid);
        }, 300);
    }

    /**
     * Gestiona cuando se preciona enter cuando se esta añadiendo una etiqueta en la tabla
     * @param params
     * @param event
     * @returns {Promise<void>}
     */
    handleCellEditKeyDown = async (params, event) => {

        await this.handleGridCellEditKeyDown(params, event, async (row, event, params) => {

            switch (params.field) {
                case 'order': {
                    const {state, updateState} = this.context;

                    if (event.target.value) {
                        let validateSticker = await this.validateSticker(row, event.target.value, updateState, state, params.id, true);

                        if (!validateSticker) {
                            row.order = undefined;
                            row.customer = undefined;
                            row.listCustomerAdress = [];
                            row.name = '';
                            row.address = '';
                            row.cp = '';
                            row.county = '';
                            row.state = '';
                            return false;
                        }

                        row.order = event.target.value;

                        if (this.state.delivery.desk && row.employee !== undefined && row.nameEmployee !== undefined) {
                            this.handleSave(row.id);
                        }

                    } else {
                        const toasts = Toasts.generateToast('No se puede añadir etiqueta sin albarán asociado', 'error');
                        updateState({toasts: [...state.toasts, toasts]});
                        return false;
                    }

                    break;
                }
                case 'employee': {
                    const {state, updateState} = this.context;
                    const employee = state.employees.find(employee => employee.id === parseInt(event.target.value));
                    if (!employee) {
                        const toasts = Toasts.generateToast('No existe empleado con ese id', 'error');
                        updateState({toasts: [...state.toasts, toasts]});
                        row.nameEmployee = '';
                        return false;
                    }
                    row.nameEmployee = employee.nombre;
                    if (this.state.delivery.desk) {
                        this.setState({employeeDefault: employee})
                    }
                    break;
                }
                default: {
                    row[params.field] = event.target.value;
                    break;
                }
            }

            return true;
        }, this.handleSave);
    };

    /**
     * Validar etiqueta
     * @param row
     * @param order
     * @param updateState
     * @param state
     * @param id
     * @returns {Promise<boolean|*>}
     */
    async validateSticker(row, order, updateState, state, id, displayModal) {

        const sticker = await StickerService.getSticker(row.serie, order, state.year);

        if (Object.keys(sticker).length === 0 || (sticker.id_ruta !== parseInt(this.props.search.get('id')) && row.overrideRoute === true) || (sticker.id_ruta === parseInt(this.props.search.get('id')) && !row.isNew)) {
            return await this.updateCustomerRow(row, order, updateState, state);
        } else if (sticker.id_ruta === parseInt(this.props.search.get('id')) && row.isNew) {
            if (displayModal) {
                this.setState({
                    showConfirmation: true,
                    sticker: new Sticker(id, row.serie, order),
                    text: 'Ya existe una etiqueta con serie: <strong>' + sticker.serie + '</strong> y albarán: <strong>' + sticker.albaran + '</strong> en esta ruta',
                    title: 'Atención etiqueta ya existe en ruta',
                    disableConfirm: true,
                    handleCancelModal: this.handleClose.bind(this, 'showConfirmation', true)
                });
            }
            return false;
        } else {
            if (displayModal) {
                let stickerOverride = new Sticker(id, row.serie, order);
                stickerOverride.delivery = sticker.id_ruta;
                this.setState({
                    showConfirmation: true,
                    sticker: stickerOverride,
                    text: 'Existe una etiqueta con serie: <strong>' + sticker.serie + '</strong> y albarán: <strong>' + sticker.albaran + '</strong> en la ruta <strong>' + sticker.ruta + '</strong><br> ¿Estas seguro que quiere crear la etiqueta?',
                    title: 'Atención etiqueta ya creada',
                    disableConfirm: false,
                    handleConfirmModal: this.handleConfirmCustomerSticker,
                    handleCancelModal: this.handleClose.bind(this, 'showConfirmation', true)
                });
            }
            return false;
        }
    }

    /**
     * Actualizar información de cliente en la etiqueta
     * @param row
     * @param order
     * @param resetCustomer
     * @param updateState
     * @param state
     * @returns {Promise<*>}
     */
    async updateCustomerRow(row, order, updateState, state) {
        const customer = await OrderService.getCustomerOrder(row.serie, order, this.state.delivery.year);

        if (customer.length > 0) {

            row.customer = customer[0].ClientesModel.NOMBRE;
            row.id_customer = customer[0].CLIENTE;
            row.way_pay = customer[0].FPagModel.NOMBRE;
            row.listCustomerAdress = customer[0].ClientesModel.EnviCliModels;
            row.tradename = customer[0].ClientesModel.NOMBRE2;
            row.phonesDefault = customer[0].ClientesModel.TelfCliModels;

            if (!Utils.isEmpty(customer[0].DIRECCION)) {
                row.name = (!Utils.isFullEmpty(customer[0].ClientesModel.NOMBRE2) ? customer[0].ClientesModel.NOMBRE2 : (!Utils.isFullEmpty(customer[0].DIRECCION.NOMBRE) ? customer[0].DIRECCION.NOMBRE : customer[0].ClientesModel.NOMBRE));
                row.address = (customer[0].DIRECCION.DIRECCION === null ? '' : customer[0].DIRECCION.DIRECCION);
                row.cp = (customer[0].DIRECCION.CODPOS === null ? '' : customer[0].DIRECCION.CODPOS);
                row.county = (customer[0].DIRECCION.POBLACION === null ? '' : customer[0].DIRECCION.POBLACION);
                row.state = (customer[0].DIRECCION.PROVINCIA === null ? '' : customer[0].DIRECCION.PROVINCIA);
                row.phone = (!Utils.isFullEmpty(customer[0].DIRECCION.TELEFONO) ? customer[0].DIRECCION.TELEFONO : (customer[0].ClientesModel.TelfCliModels.length > 0 && !Utils.isFullEmpty(customer[0].ClientesModel.TelfCliModels[0]?.TELEFONO) ? customer[0].ClientesModel.TelfCliModels[0]?.TELEFONO : ''));
            }

        } else {
            const toasts = Toasts.generateToast('No existe albaran con serie: ' + row.serie + ' - número: ' + order, 'error');
            updateState({toasts: [...state.toasts, toasts]});
            return false;
        }

        return true;
    }

    reactToPrintContent = () => {
        return this.componentRef;
    };

    setComponentRef = (ref) => {
        this.componentRef = ref;
    };

    setComponentPrintRef = (ref) => {
        this.componentPrintRef = ref;
    };

    handleIframeLoad = () => {
        let sticker = this.state.print;
        if (!sticker.print){
            sticker.print = true;
            StickerService.registerSticker(sticker).then(() => {
                this.apiRef.current.updateRows([
                    {
                        id: sticker.id,
                        ...sticker,
                    },
                ]);
            });
            const {state} = this.context;
            OrderService.assignToOrder(sticker.serie + '-' + sticker.order, null, null, 'ventas', '3', false, undefined, state.year, null);
        }

        if (this.state.delivery?.asigna) {
            this.stickerAsignaRef.current.handleIframeLoad();
        }
    }

    handleMarkSticker = async () => {
        const {state, updateState} = this.context;

        let sticker = this.apiRef.current.getRow(this.state.order);
        if (sticker !== null && !sticker.check) {
            sticker.check = true;
            this.processRowUpdate(sticker, this.apiRef.current.getRowWithUpdatedValues(this.state.order));
            Utils.playAudio(successSound);
        } else if (sticker !== null && sticker.check) {
            const toasts = Toasts.generateToast('Etiqueta ya marcada', 'warning');
            updateState({toasts: [...state.toasts, toasts]});
            Utils.playAudio(warningSound);
        } else {
            const toasts = Toasts.generateToast('No existe etiqueta para ' + this.state.order, 'error');
            updateState({toasts: [...state.toasts, toasts]});
            Utils.playAudio(errorSound);
        }

        this.setState({order: ''});
    }

    handleSubmitOrder(event) {

        if (event) {
            event.preventDefault();
        }

        const {state, updateState} = this.context;

        if (this.state.order !== "" && !Utils.checkOrder(this.state.order)) {
            const toasts = Toasts.generateToast('Formato de albarán inválido: SERIE-ALBARAN', 'error');
            updateState({toasts: [...state.toasts, toasts]});
            Utils.playAudio(warningSound);
        } else if (this.state.order !== "" && Utils.checkOrder(this.state.order)) {
            if (this.state.view) {
                this.handleMarkSticker();
            } else {
                this.handleLoadOrder();
            }
        }
    }

    handleLoadOrder = async () => {

        const id = this.state.order.split('-');
        let sticker = new Sticker();
        sticker.id = Date.now();
        sticker.delivery = this.props.search.get('id');
        sticker.nameDelivery = this.props.params.delivery;
        sticker.agency = this.state.delivery.transport;
        sticker.nameAgency = this.state.transport.NOMBRE;
        sticker.created = new Date();
        sticker.serie = id[0];
        sticker.order = id[1];
        sticker.isNew = true;

        if (this.state.delivery.desk) {
            sticker.packages = 0;
            if (this.state.employeeDefault !== null) {
                sticker.employee = this.state.employeeDefault.id;
                sticker.nameEmployee = this.state.employeeDefault.nombre;
            }
        }

        await this.clearGridRowEdit('stickers', 'stickersModels');

        this.setState({
            ['stickers']: [sticker, ...this.state['stickers']]
        });

        this.setState({
            ['stickersModels']: {
                [sticker.id]: {mode: GridRowModes.Edit, fieldToFocus: 'order'},
                ...this.state['stickersModels']
            }
        });
    }

    handleChangeOrder = (event) => {
        this.setState({order: event.target.value});
    }

    render() {

        const {
            delivery,
            stickers,
            showComment,
            showAddress,
            showWeight,
            sticker,
            loading,
            stickersModels,
            print,
            showConfirmation,
            title,
            text,
            disableConfirm,
            view,
            handleConfirmModal,
            handleCancelModal
        } = this.state;
        const barito = this.state.delivery?.barito;
        const asigna = this.state.delivery?.asigna;
        const columns = this.generateColumns(barito, asigna);
        const {state} = this.context;

        return (
            <CCol id="stickers" md={12} className={view ? 'view' : ''}>
                <CCard className="p-3">
                    <CCardBody>
                        <DeliveryNavigate delivery={delivery} year={delivery.year} page={!view ? 'sticker' : 'view'}/>
                        <div id='info-delivery'>
                            <div className='row'>
                                <div className="mb-3 col-2 col ">
                                    <div className="col-12 d-inline-flex align-items-center">
                                        <label htmlFor="preparer"
                                               className="form-label mb-1 me-2">Id:</label>
                                        <div className="form-text mt-0 mb-1">{delivery.id}</div>
                                    </div>
                                    <div className="col-12 d-inline-flex align-items-center">
                                        <label htmlFor="preparer"
                                               className="form-label mb-1 me-2">Nombre:</label>
                                        <div className="form-text mt-0 mb-1">{delivery.name}</div>
                                    </div>
                                    <div className="col-12 d-inline-flex align-items-center">
                                        <label htmlFor="preparer"
                                               className="form-label mb-1 me-2">Fecha:</label>
                                        <div
                                            className="form-text mt-0 mb-1">{Utils.formatDateWithoutHour(Date.parse(delivery.date))}</div>
                                    </div>
                                </div>
                                <div className="mb-3 col-2 col align-items-center">
                                    <div className="col-12 d-inline-flex align-items-center">
                                        <label htmlFor="preparer"
                                               className="form-label mb-1 me-2">Bultos:</label>
                                        <div className="form-text mt-0 mb-1">{delivery.packages}</div>
                                    </div>
                                    <div className="col-12 d-inline-flex align-items-center">
                                        <label htmlFor="preparer"
                                               className="form-label mb-1 me-2">Expediciones:</label>
                                        <div className="form-text mt-0 mb-1">{delivery.expedition}</div>
                                    </div>
                                    <div className="col-12 d-inline-flex align-items-center">
                                        <label htmlFor="preparer"
                                               className="form-label mb-1 me-2">Sage:</label>
                                        <div className="form-text mt-0 mb-1">{delivery.counter}</div>
                                    </div>
                                    {delivery.barito ?
                                        <div className="col-12 d-inline-flex align-items-center">
                                            <label htmlFor="preparer"
                                                   className="form-label mb-1 me-2">M3:</label>
                                            <div className="form-text mt-0 mb-1">{delivery.weight}</div>
                                        </div>
                                        : ''}
                                </div>

                            </div>
                                {view ?
                                <div className='row'>
                                    <div className="mb-3 col align-items-center">
                                        <Button startIcon={<PrintIcon/>} className='bg-primary text-white'
                                                onClick={() => this.handlePrint()}>
                                            Imprimir
                                        </Button>
                                        <Button startIcon={<PrintIcon/>}
                                                className='bg-primary ms-2 text-white me-2'
                                                onClick={() => this.handleExport()}>
                                            Exportar excel
                                        </Button>
                                    </div>
                                </div>
                                    : ''}
                                <CCard className="p-0 mb-3">
                                    <CCardBody>
                                        <CForm onSubmit={(e) => this.handleSubmitOrder(e)} className="mb-3">
                                            <div className="mb-3">
                                                <label htmlFor="threshold" className="form-label">{view ? 'Marcar etiqueta' : 'Añadir etiqueta'} </label>
                                                <CFormInput ref={this.orderRef} className="form-control" type="text" id="order" name="order"
                                                            value={this.state.order}
                                                            onChange={(e) => this.handleChangeOrder(e)}/>
                                            </div>
                                            <CButton type="submit" color="primary" className="px-4 col-12">
                                                {state.loading ?
                                                    <CSpinner component="span" size="sm"
                                                              aria-hidden="true"/> : view ? 'Marcar' : 'Añadir'}
                                            </CButton>
                                        </CForm>
                                    </CCardBody>
                                </CCard>
                        </div>

                        <ReactToPrint ref={this.setComponentPrintRef}
                                      content={this.reactToPrintContent}
                                      onAfterPrint={this.handleIframeLoad}
                        />

                        {!view ?
                            <>
                                <PrintSticker ref={this.setComponentRef} print={print}/>
                            </>
                            : <>
                                <PrintDelivery ref={this.setComponentRef} print={print} delivery={delivery}/>
                            </>}

                        {delivery.asigna ? <PrintStickerAsigna ref={this.stickerAsignaRef}/>
                            : ''}

                        <DataGrid
                            rows={stickers}
                            columns={columns}
                            // slots={{
                            //     toolbar: this.editToolbar,
                            // }}
                            rowModesModel={stickersModels}
                            onRowEditStop={this.handleRowEditStop}
                            onRowEditStart={this.handleRowEditStart}
                            onRowModesModelChange={this.handleRowModesModelChange}
                            processRowUpdate={this.processRowUpdate}
                            onProcessRowUpdateError={this.onProcessRowUpdateError}
                            onCellKeyDown={this.handleCellEditKeyDown}
                            disableRowSelectionOnClick={true}
                            apiRef={this.apiRef}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        created: false,
                                        id_customer: false,
                                        way_pay: false,
                                        agency: false
                                    },
                                },
                                sorting: {
                                    sortModel: [{field: 'created', sort: 'desc'}],
                                },
                            }}
                            getRowClassName={(params) => {
                                if (view) {
                                    return params.row.scanned ? "success" : "danger";
                                }
                                return '';
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            editMode="row"
                            autoHeight={true}
                            loading={loading}
                        />
                    </CCardBody>
                </CCard>

                {!view ?
                    <>

                        <ConfirmationM show={showConfirmation} data={sticker}
                                       title={title}
                                       text={text}
                                       iconName='cilWarning'
                                       disableConfirm={disableConfirm}
                                       handleClose={handleCancelModal}
                                       handleConfirm={handleConfirmModal}/>

                        <Comment show={showComment}
                                 sticker={sticker}
                                 handleClose={() => this.handleClose('showComment')}
                                 handleConfirm={(sticker) => this.handleConfirm(sticker, 'comment')}></Comment>

                        <Address show={showAddress} sticker={sticker}
                                 handleClose={() => this.handleClose('showAddress')}
                                 handleConfirm={(sticker) => this.handleConfirm(sticker, 'address')}/>
                        {barito ?
                            <Weight show={showWeight} sticker={sticker}
                                    handleClose={() => this.handleClose('showWeight')}
                                    handleConfirm={(sticker) => this.handleConfirm(sticker, 'boxes')}/> : ''}
                    </>
                    : ''}

            </CCol>
        )
    }
}

export default withRouter(Stickers);