import axios from "axios";
import HostService from "./host.service";
import AuthService from "./auth.service";
import Sticker from "../models/Sticker";


class StickerService {

    /**
     * Registrar etiquetas
     * @param sticker
     * @returns {Promise<AxiosResponse<any>>}
     */
    static registerSticker(sticker) {
        return axios.post(HostService.getHost() + 'etiquetas',
            {
                "serie": sticker.serie,
                "albaran": sticker.order.toString(),
                "cliente": sticker.customer,
                "id_ruta": sticker.delivery,
                "ruta": sticker.nameDelivery,
                "id_agencia": sticker.agency,
                "agencia": sticker.nameAgency,
                "bultos": sticker.packages,
                "nombre": sticker.name,
                "direccion": sticker.address,
                "cp": sticker.cp,
                "poblacion": sticker.county,
                "provincia": sticker.state,
                "telefono": sticker.phone,
                "peso": sticker.weight,
                "pesos": sticker.boxes,
                "id_empleado": sticker.employee,
                "empleado": sticker.nameEmployee,
                "comprobado": sticker.check,
                "id_cliente": sticker.id_customer,
                "forma_pago": sticker.way_pay,
                "expedicion_asigna": sticker.expedicionAsigna,
                "fecha": sticker.dateDelivery,
                "contador": sticker.counterDelivery,
                "dropshipping": sticker.dropshipping,
                "imprimir": sticker.print,
                "comentario": sticker.comment
            }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener etiquetas
     * @param id_agency
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getStickers(id_agency) {
        return axios.get(HostService.getHost() + 'etiquetas/' + id_agency, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener etiqueta
     * @param serie
     * @param order
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getSticker(serie, order, year) {
        return axios.get(HostService.getHost() + 'etiquetas/' + serie + '/' + order +'?ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener etiqueta
     * @param serie
     * @param order
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getStickerMap(serie, order, year) {
        return axios.get(HostService.getHost() + 'etiquetas/' + serie + '/' + order +'?ejercicio='+year, AuthService.setToken())
            .then((response) => {
                const data = response.data;
                if (Object.keys(data).length !== 0) {
                    return new Sticker(data.serie + '-' + data.albaran,
                        data.serie, data.albaran, data.cliente, data.bultos, data.id_agencia, data.agencia,
                        data.nombre, data.direccion, data.cp, data.poblacion, data.provincia, data.id_ruta, data.ruta, data.telefono,
                        data.id_empleado, data.empleado, data.comprobado, data.creado, data.id_cliente, data.forma_pago, data.expedicion_asigna,
                        null, null, data.dropshipping, data.imprimir
                    );
                }
                return null;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Borrar etiqueta
     * @param id_order
     * @returns {Promise<AxiosResponse<any>>}
     */
    static deleteSticker(serie, order, counter, year) {
        return axios.delete(HostService.getHost() + 'etiquetas/' + serie + '/' + order + '?contador=' + counter + '&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }
}

export default StickerService;