import React from "react";
import {CBadge, CCard, CCardBody, CCardTitle, CFormInput, CFormSelect} from "@coreui/react";
import EmployeesService from "../../services/employees.service";
import Toasts from "../notificacion/Toasts";
import {StateContext} from "../context/StateProvider";
import OrderService from "../../services/order.service";
import ReactToPrint from "react-to-print";
import PrintOrder from "./PrintOrder";
import OrderStates from "../../utils/OrderStates";
import Utils from "../../utils/Utils";
import AsignaService from "../../services/asigna.service";
import HandleExceptions from "../../exceptions/HandleExceptions";


class InfoOrder extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {reviewers: [], preparers: [], orderState: [], type: ''};
        this.cameraRef = React.createRef();
        this.timeout = null;
        this.debounceTime = 1000;
    }

    async componentDidMount() {

        this.setState({
            preparers: await this.initSelects('Elige preparador')
        });

        this.setState({
            reviewers: await this.initSelects('Elige revisor')
        });

        this.setState({orderState: OrderStates.getStatesJson()})
    }

    /**
     * Iniciar array de opciones de select
     * @param texto
     * @returns {Promise<*>}
     */
    async initSelects(texto) {

        let employees = await EmployeesService.getEmployees();

        let employeesMap = employees.map((employee) => {
            return {label: employee.nombre, value: employee.nombre.toLowerCase()}
        });

        employeesMap.unshift({label: texto, value: '0'});
        return employeesMap;
    }

    /**
     * Gestionar cambio de selects
     * @param event
     * @param type
     */
    handleChange(value, type, order, n2) {

        const {state, updateState} = this.context;

        let preparer = null;
        let reviewer = null;
        let orderStateN2 = null;
        let camera = null;
        let texto = '';
        if (type === 'preparer') {
            preparer = value;
            texto = 'preparador';
        } else if (type === 'reviewer') {
            reviewer = value;
            if (value !== 0) {
                orderStateN2 = 4;
            } else {
                orderStateN2 = 1;
            }
            texto = 'revisor';
            state.order.state = orderStateN2;
            updateState({order: state.order});
        } else if (type === 'camera') {
            camera = value;
            texto = 'camara';
            state.order.camera = value;
            state.order.editing = true;
            updateState({order: state.order});
        } else {
            orderStateN2 = value;
            texto = 'estado';
        }

        if (type !== 'camera') {
            this.assignToOrder(reviewer, preparer, order, n2, orderStateN2, camera, texto, type);
        } else {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.assignToOrder(reviewer, preparer, order, n2, orderStateN2, camera, texto, type)
            }, this.debounceTime);
        }
    }

    assignToOrder = (reviewer, preparer, order, n2, orderStateN2, camera, texto, type) => {

        const {state, updateState} = this.context;

        OrderService.assignToOrder(state.order.num, reviewer, preparer, order, orderStateN2, n2, undefined, state.year, camera).then(
            () => {
                const toasts = Toasts.generateToast('Asignado ' + texto + ' al pedido', 'success');
                updateState({toasts: [...state.toasts, toasts]});

                if (type === 'preparer') {
                    state.order.preparer = preparer;
                } else if (type === 'reviewer') {
                    state.order.reviewer = reviewer;
                } else if (type === 'camera') {
                    state.order.editing  = false;
                } else {
                    state.order.state = orderStateN2;
                }

                if ((state.order.camera === null || state.order.camera === '') && state.order.reviewer !== '0' && state.order.preparer !== '0') {
                    this.cameraRef.current?.focus();
                }

                updateState({order: state.order});
            }
        ).catch((error) => {
            const toasts = Toasts.generateToast(error.response?.data?.message, 'error');
            updateState({toasts: [...state.toasts, toasts]})
        });
    }

    setComponentRef = (ref) => {
        this.componentRef = ref;
    };

    reactToPrintContent = () => {
        return this.componentRef;
    };

    async handleGetNote(e) {
        e.preventDefault();

        const {state, updateState} = this.context;

        const resultGetNote = await AsignaService.getNote(state.order.num);
        if (resultGetNote.data) {
            const base64String = resultGetNote.data;
            const decodedBytes = atob(base64String);
            const byteNumbers = new Array(decodedBytes.length);
            for (let i = 0; i < decodedBytes.length; i++) {
                byteNumbers[i] = decodedBytes.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'application/pdf'});
            const urlObject = URL.createObjectURL(blob);

            // Crear un enlace dinámico para la descarga
            const a = document.createElement('a');
            a.href = urlObject;
            a.download = 'note.pdf'; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Liberar el objeto URL para evitar fugas de memoria
            URL.revokeObjectURL(urlObject);
        } else {
            HandleExceptions.throwException("Error obteniendo etiqueta en asigna:" + resultGetSticker.message);
        }
    }

    render() {

        const {state} = this.context;
        const {type, order, read, refDiv, preparer, reviewer, camera, orderStateN2} = this.props;

        return (
            <CCard id='info-order'>
                <CCardBody className='d-flex flex-column'>
                    <div className='d-flex flex-row col-12'>
                        <div className={state.order.delivery === null && state.order.sticker === null ? "d-flex col-12 flex-column" : "d-flex col-6 flex-column"}>
                            <CCardTitle
                                className="d-flex flex-row col-12 mb-3">{'Información de'.toUpperCase()} {type.toUpperCase()}</CCardTitle>
                            <div className='row'>
                                <div className="mb-2 col-12 d-inline-flex align-items-center">
                                    <label htmlFor="preparer"
                                           className="form-label mb-1 me-2">{type.charAt(0).toUpperCase() + type.slice(1)}:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.num}</div>
                                </div>
                            </div>
                            {state.order.state && !orderStateN2 ?
                            <div className='row'>
                                <div className="mb-2 col-12 d-inline-flex align-items-center">
                                    <label htmlFor="preparer"
                                           className="form-label mb-1 me-2">Estado:</label>
                                    <div className="form-text mt-0 mb-1"><CBadge
                                        color={state.order.state === '1' ? 'info' : (state.order.state === '2' ? 'danger' : (state.order.state === '4' ? 'warning' : 'success'))}>{OrderStates.getState(state.order.state)}</CBadge>
                                    </div>
                                </div>
                            </div>
                                : ''}
                            {preparer ?
                                <div className='row' ref={refDiv}>
                                    <div
                                        className={!state.order.complete ? "mb-2" : "mb-2 col-12 d-inline-flex align-items-center"}>
                                        <label htmlFor="preparer" className="form-label mb-1 me-2">Preparador:</label>
                                        {!state.order.complete ?
                                            <CFormSelect className='form-control'
                                                         onChange={(e) => this.handleChange(e.target.value, 'preparer', order, orderStateN2)}
                                                         id="preparer"
                                                         name="preparer" value={state.order.preparer}
                                                         aria-label="Seleccionar preparador"
                                                         options={this.state.preparers}
                                            />
                                            : <div
                                                className="form-text mt-0 mb-1">{state.order.preparer !== '0' ? state.order.preparer.charAt(0).toUpperCase() + state.order.preparer.slice(1) : 'Ninguno'}</div>}
                                    </div>
                                </div>
                                : ''}
                            {reviewer ?
                                <div className='row'>
                                    <div
                                        className={!state.order.complete ? "mb-2" : "mb-2 col-12 d-inline-flex align-items-center"}>
                                        <label htmlFor="reviewer" className="form-label mb-1 me-2">Revisor:</label>
                                        {!state.order.complete ?
                                            <CFormSelect className='form-control mb-1'
                                                         onChange={(e) => this.handleChange(e.target.value, 'reviewer', order, orderStateN2)}
                                                         id="reviewer"
                                                         name="reviewer" value={state.order.reviewer}
                                                         aria-label="Seleccionar revisor"
                                                         options={this.state.reviewers}
                                            />
                                            : <div
                                                className="form-text mt-0 mb-1">{state.order.reviewer !== '0' ? state.order.reviewer.charAt(0).toUpperCase() + state.order.reviewer.slice(1) : 'Ninguno'}</div>}
                                    </div>
                                </div>
                                : ''}
                            { state.order.sticker ?
                                <div className='row'>
                                    <div
                                        className="mb-2 col-12 d-inline-flex align-items-center">
                                        <label htmlFor="sticker" className="form-label mb-1 me-2">Etiquetado por:</label>
                                        <div className="form-text mt-0 mb-1">{state.order.sticker.nameEmployee ? state.order.sticker.nameEmployee : ''}</div>
                                    </div>
                                </div>
                                : ''
                            }
                            {camera ?
                                <div className='row'>
                                    <div
                                        className={!state.order.complete ? "" : "col-12 d-inline-flex align-items-center"}>
                                        <label htmlFor="camera" className="form-label mb-1 me-2">Camara:</label>
                                        {!state.order.complete ?
                                            <CFormInput ref={this.cameraRef} className="form-control" type="text" id="camera" name="camera"
                                                        onChange={(e) => this.handleChange(e.target.value, 'camera', order, orderStateN2)}
                                                        value={state.order.camera !== null ? state.order.camera : ''}/>
                                            : <div
                                                className="form-text mt-0 mb-1">{state.order.camera ? state.order.camera : ''}</div>}
                                    </div>
                                </div>
                                : ''
                            }
                            {orderStateN2 ?
                                <>
                                    <div className='row'>
                                        <div
                                            className={!state.order.complete ? "mb-2" : "mb-2 col-12 d-inline-flex align-items-center"}>
                                            <label htmlFor="orderStateN2" className="form-label mb-1 me-2">Estado:</label>
                                            {!state.order.complete ?
                                                <CFormSelect className='form-control mb-1'
                                                             onChange={(e) => this.handleChange(e.target.value, 'orderState', order, orderStateN2)}
                                                             id="orderStateN2"
                                                             name="orderStateN2"
                                                             value={state.order.state ? state.order.state : ''}
                                                             aria-label="Seleccionar estado"
                                                             options={this.state.orderState}
                                                />
                                                : <div className="form-text mt-0 mb-1"><CBadge
                                                    color={state.order.state === '1' ? 'info' : (state.order.state === '2' ? 'danger' : 'success')}>{OrderStates.getState(state.order.state)}</CBadge>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {orderStateN2 && state.order.preparer !== '0' && state.order.state !== '0' && !state.order.complete ?
                                        <div className='row'>
                                            <div className="mt-2 col-12 d-inline-flex align-items-center">
                                                <ReactToPrint
                                                    onAfterPrint={() => {
                                                        this.handleChange('2', 'orderStateN2', order, orderStateN2)
                                                    }}
                                                    trigger={() => {
                                                        return <button
                                                            className="btn btn-primary col-12">Imprimir</button>;
                                                    }}
                                                    content={this.reactToPrintContent}
                                                />
                                                <PrintOrder ref={this.setComponentRef}/>
                                            </div>
                                        </div>
                                        : ''}
                                </>
                                : ''}
                            {!read & !orderStateN2 ?
                                <>
                                    {state.order.n2 ?
                                        <>
                                            <div className='row'>
                                                <div className="mt-2 col-12 d-inline-flex align-items-center">
                                                    <label className="form-label mb-1 me-2">Pedido en nave 2:</label>
                                                    <div className="form-text mt-0 mb-1"><CBadge
                                                        color={state.order.stateN2 === '1' ? 'info' : (state.order.stateN2 === '2' ? 'danger' : 'success')}>{OrderStates.getState(state.order.stateN2)}</CBadge>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="mt-2 col-12 d-inline-flex align-items-center">
                                                    <label className="form-label mb-1 me-2">Preparador nave 2:</label>
                                                    <div
                                                        className="form-text mt-0 mb-1">{state.order.preparerN2 !== '0' ? state.order.preparerN2.charAt(0).toUpperCase() + state.order.preparerN2.slice(1) : 'Ninguno'}</div>
                                                </div>
                                            </div>
                                        </>
                                        : ''}
                                    {state.order.lastUpdated ?
                                        <div className='row'>
                                            <div className="mt-2 col-12 d-inline-flex align-items-center">
                                                <label className="form-label mb-0 me-2">Última
                                                    actualización:</label>
                                                <div className="form-text mt-0 mb-0">{state.order.lastUpdated}</div>
                                            </div>
                                        </div>
                                        : ''}
                                </>
                                : ''}
                        </div>
                        {state.order.complete && state.order.delivery !== null && state.order.sticker !== null ?
                        <div className='d-flex col-6 flex-column'>
                            <CCardTitle
                                className="d-flex flex-row col-12 mb-3">{'Detalles ruta'.toUpperCase()}</CCardTitle>
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="id" className="form-label mb-1 me-2">Id:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.delivery.id ? state.order.delivery.id : ''}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="sage" className="form-label mb-1 me-2">Id Sage:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.delivery.counter ? state.order.delivery.counter : ''}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="name" className="form-label mb-1 me-2">Nombre:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.delivery.name ? state.order.delivery.name : ''}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="date" className="form-label mb-1 me-2">Fecha:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.delivery.date ? Utils.formatDateWithoutHour(Date.parse(state.order.delivery.date)) : ''}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="agency" className="form-label mb-1 me-2">Agencia:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.sticker.nameAgency ? state.order.sticker.nameAgency : ''}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="packages" className="form-label mb-1 me-2">Bultos:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.sticker.packages ? state.order.sticker.packages : ''}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="complete" className="form-label mb-1 me-2">Expedición consolidada:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.packages?.length === state.order.sticker.packages ? 'Si' : 'No'}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="last-package" className="form-label mb-1 me-2">Fecha último bulto consolidado:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.lastRegisterPackage ? Utils.formatDate(state.order.lastRegisterPackage) : ''}</div>
                                </div>
                            </div>
                            {state.order.sticker.expedicionAsigna !== null && state.order.delivery.asigna ?
                            <div className='row'>
                                <div
                                    className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="expedition" className="form-label mb-1 me-2">Nº de seguimiento:</label>
                                    <div className="form-text mt-0 mb-1">{state.order.sticker.expedicionAsigna ? <>{state.order.sticker.expedicionAsigna}
                                        <a className='ms-1' target="_blank" href={window.env.ASIGNA_URL+state.order.sticker.expedicionAsigna}>Ver seguimiento</a>
                                        <a className='ms-1' target="_blank" href="#" onClick={(e)=>this.handleGetNote(e, state.order.num)}>Ver imagen</a>
                                    </>  : ''}</div>
                                </div>
                            </div>
                                : ''}
                        </div>
                        : ''}
                    </div>
                </CCardBody>
            </CCard>
        );
    }
}

export default InfoOrder;