import React from "react";
import {StateContext} from "../context/StateProvider";
import Utils from "../../utils/Utils";
import {FORMAT_LOCALE_DIGIT, LOCALE} from "../../config/constants";

class PrintOrder extends React.Component {

    static contextType = StateContext;

    render() {

        const {state} = this.context;

        return <>
            {state.products.length > 0 ?
                <div id="print-ticket" className="hidden m-4">
                    <div className='row'>
                        <div className="mb-2 col-12 d-inline-flex align-items-center">
                            <div className="form-label mb-1 me-2 text-black"><h2>Albarán: {state.order.num}</h2></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="p-2 col-12 d-inline-flex align-items-center border-top-black">
                            <div className="form-label mb-1 me-2 text-black"><strong>Preparador:</strong></div>
                            <div className="form-text mt-0 mb-1 text-black">{Utils.toCapitalize(state.order.preparer)}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="p-2 col-12 d-inline-flex align-items-center border-top-black">
                            <div className="form-label mb-1 me-2 text-black"><strong>Ruta:</strong></div>
                            <div className="form-text mt-0 mb-1 text-black">{Utils.toCapitalize(state.order.route)}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="p-2 col-12 d-inline-flex align-items-center border-top-black">
                            <div className="form-label mb-1 me-2 text-black"><strong>Fecha:</strong></div>
                            <div className="form-text mt-0 mb-1 text-black">{new Date().toLocaleString(LOCALE, {
                                day: FORMAT_LOCALE_DIGIT,
                                month: FORMAT_LOCALE_DIGIT,
                                year: FORMAT_LOCALE_DIGIT,
                                hour: FORMAT_LOCALE_DIGIT,
                                minute: FORMAT_LOCALE_DIGIT,
                                second: FORMAT_LOCALE_DIGIT,
                                hour12: false
                            })}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <table className="table mb-0 text-black">
                            <thead>
                            <tr>
                                <th><strong>Pedido</strong></th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.products.map(function (product, index) {
                                return (
                                    <OrderPrintProduct key={index} product={product}/>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                : ''}
        </>;
    }
}

class OrderPrintProduct extends React.Component {
    render() {

        const props = this.props;

        return (
            <tr>
                <td>
                    <div><strong>Ref:</strong> {props.product.ref}</div>
                    <div><strong>C. barras:</strong> {props.product.ean.map(item => item.BARRAS + '-' + item.UNIDADES).join(', ')}</div>
                    <div><strong>Desc:</strong> {props.product.name}</div>
                    <div><strong>Unids:</strong> {props.product.units}</div>
                    <div><strong>Ubic:</strong> {props.product.location}</div>
                </td>
            </tr>
        );
    }
}

export default PrintOrder;
